import { Injectable } from '@angular/core';
import { Incident } from '../models/incident';
import { environment } from '../../../environments/environment';
import { Role } from '../models/role';
import { getUserFromToken, GlobalConstants } from '../models/global.constants';
import { CurrentUser } from '../models/currentUser';
import { AuthService } from '../../auth/auth.service';
import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const PEERSURL = environment.meetingUrl + 'api/v1/peers';

@Injectable({
	providedIn: 'root',
})
export class MeetingRoomService {
	public currentUser: CurrentUser = getUserFromToken();

	constructor(private authService: AuthService, private http: HttpClient) {}

	public joinMeetingRoom(incident: Incident): void {
		if (incident.incidentId && incident.reports[0]) {
			const url = this.createUrlFromRoomName(incident.incidentId, incident.reports[0].incidentReportId);
			window.open(url, '_blank');
		}
	}

	public getRoleForRoom(): string {
		if (this.currentUser.role === Role.ASM || this.currentUser.role === Role.Administrator) {
			return 'expert';
		} else {
			return 'employee';
		}
	}

	//Demo Meeting Url: https://mirotalk.bcx-test.de/join?room=8e9d3e90-fcb8-4026-8a40-1c0ef86da09e&name=talkp2p&role=employee&irid=3fa85f64-5717-4562-b3fc-2c963f66afa6&token=eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI4YjVkNDMzZS1mMzZiLTE0MTAtOGJhZC0wMDE1Yzg0MTQ4OWQiLCJuYmYiOjE2NTI2ODU5MzIsImV4cCI6MTY1Mzg5NTUzMiwiaWF0IjoxNjUyNjg1OTMyLCJpc3MiOiJodHRwczovL2FwaS5wbG9lY2hpbmdlci1yaS5kZSIsImF1ZCI6Imh0dHBzOi8vYXBpLnBsb2VjaGluZ2VyLXJpLmRlIn0.rhzS_S2t2hI5_kTN-mmPVvUEXSp6BxUSTD81ly8v5QcQ8WQ5GLyWkJZwvTVeKt-HB020nmahPLQtrwwMVXtUeQ
	createUrlFromRoomName(roomName: string, irid: string): string {
		const userName = this.currentUser.email;
		const token = localStorage.getItem(GlobalConstants.JWT);
		const role = this.getRoleForRoom();
		return environment.meetingUrl + 'join?room=' + roomName + '&name=' + userName + '&role=' + role + '&irid=' + irid + '&token=' + token;
	}

	public getCurrentOpenMeetingRoom(): Promise<any> {
		const result$ = this.http.get<any>(PEERSURL);
		return lastValueFrom(result$);
	}
}
