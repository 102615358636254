import { Injectable, OnInit } from '@angular/core';
import { Role } from '../models/role';
import { User } from '../models/user';
import { ViewOption } from '../models/view-option';
import { CurrentUser } from '../models/currentUser';
import { getUserFromToken } from '../models/global.constants';

@Injectable({
	providedIn: 'root',
})
export class PermissionsService implements OnInit {
	public viewOptions: ViewOption[];
	public selectedOption: ViewOption;
	public currentUser: CurrentUser = getUserFromToken();
	public currentRole: Role = this.currentUser.role;

	constructor() {
		this.viewOptions = [
			{ label: 'ASM', value: Role.ASM },
			{ label: 'User', value: Role.User },
		];
		if (this.currentRole === Role.ASM || this.currentRole === Role.Administrator) {
			this.selectedOption = this.viewOptions[0];
		} else {
			this.selectedOption = this.viewOptions[1];
		}
	}

	ngOnInit() {}

	public isGranted(role: Role | Role[]): boolean {
		return (role.length && role.includes(this.currentRole)) || role === this.currentRole;
	}
}
