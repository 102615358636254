import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ButtonModule } from 'primeng/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiJwtInterceptor } from './auth/api-jwt.interceptor';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/guards/auth.guard';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, SharedModule, CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, HttpClientModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiJwtInterceptor,
			multi: true,
		},
		{
			provide: JwtHelperService,
			useFactory: () => new JwtHelperService(),
		},
		AuthService,
		AuthGuard,
	],
	exports: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
