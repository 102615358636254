<p class='spacing'></p>
<p-header>
  <div class='grid custom-header'>
    <div class='col-4 flex align-items-center justify-content-center'>
      <i style='color: #22C36D' class='pi pi-clock custom-icon'></i>
      <h1 style='color: #001E59' class='custom-value'> {{now | date: "HH:mm:ss"}} </h1>
    </div>
    <div class='col-4 flex align-items-center justify-content-center'>
      <img alt='ploechinger-logo' class='mobile-logo' id='logo-mobile' src='assets/images/logo.png' />
    </div>
    <div class='col-4 flex align-items-center justify-content-center'>
      <i style='color: #22C36D' class='pi pi-video custom-icon'></i>
      <h1 style='color: #001E59' class='custom-value'>{{rooms.length}} </h1>
      <!-- add open rooms here -->
    </div>
  </div>
</p-header>
<body>
<p-table [value]='rooms' dataKey='id' [rows]='5' [showCurrentPageReport]='true' styleClass='p-datatable-gridlines'
         [paginator]='true'>
  <ng-template pTemplate='header'>
    <tr>
      <th class='col-4 custom-th'>Service</th>
      <th class='col-4 custom-th'></th>
      <th class='col-4 custom-th'>ASM</th>
    </tr>
  </ng-template>
  <ng-template pTemplate='body' let-room>
    <tr [ngClass]='getWaitingTime(room.employee_time)'>
      <td class='col-4'>
        <div class='grid'>
          <div class='col-12 flex align-items-center justify-content-center'>
            <p class='flex justify-content-center align-items-center custom-p'>{{room.employee_name}}</p>
          </div>
          <div class='col-12 flex align-items-center justify-content-center'>
            <p class='custom-p'>{{room.employee_time}}</p>
          </div>
        </div>
      </td>
      <td class='col-4'>
        <div *ngIf='room.incident' class='grid'>
          <div class='col-12 flex align-items-center justify-content-center'>
            <p  class='flex justify-content-center align-items-center custom-p'>
              {{room.incident.vehicleRegistration ? room.incident.vehicleRegistration.cDotOneDotTwo + ' ' + room.incident.vehicleRegistration.cDotOneDotOne : ''}}
            </p>
          </div>
          <div class='col-12 flex align-items-center justify-content-center'>
            <p class='custom-p'>
              {{room.incident.vehicleRegistration && room.incident.vehicleRegistration.a ? room.incident.vehicleRegistration.a : ''}}
            </p>
          </div>
          <div class='col-12 flex align-items-center justify-content-center'>
            <p-chip>
             <p class='custom-p custom-color'>{{getEnumName(room.incident.incidentType && room.incident.incidentType ? room.incident.incidentType : '')}}</p>
            </p-chip>
          </div>
        </div>
      </td>
      <td class='col-4'>
        <div class='grid'>
          <div class='col-12 flex align-items-center justify-content-center'>
            <p class='flex justify-content-center align-items-center custom-p'>Nicht verbunden</p>
          </div>
          <div class='col-12 flex align-items-center justify-content-center'>
            <p class='custom-p'><i class='pi pi-times-circle custom-p' style='color: red'></i></p>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-table [value]='rooms' dataKey='id' [rows]='5' [showCurrentPageReport]='true' styleClass='p-datatable-gridlines'
				 [paginator]='true'>
	<ng-template pTemplate='header'>
		<tr>
			<th class='col-4 custom-th'>Service</th>
			<th class='col-4 custom-th'></th>
			<th class='col-4 custom-th'>ASM</th>
		</tr>
	</ng-template>
	<ng-template pTemplate='body' let-room>
		<tr [ngClass]='getWaitingTime(room.employee_time)'>
			<td class='col-4'>
				<div class='grid'>
					<div class='col-12 flex align-items-center justify-content-center'>
						<p class='flex justify-content-center align-items-center custom-p'>{{room.employee_name}}</p>
					</div>
					<div class='col-12 flex align-items-center justify-content-center'>
						<p class='custom-p'>{{room.employee_time}}</p>
					</div>
				</div>
			</td>
			<td class='col-4'>
				<div *ngIf='room.incident' class='grid'>
					<div class='col-12 flex align-items-center justify-content-center'>
						<p  class='flex justify-content-center align-items-center custom-p'>
							{{room.incident.vehicleRegistration ? room.incident.vehicleRegistration.cDotOneDotTwo + ' ' + room.incident.vehicleRegistration.cDotOneDotOne : ''}}
						</p>
					</div>
					<div class='col-12 flex align-items-center justify-content-center'>
						<p class='custom-p'>
							{{room.incident.vehicleRegistration && room.incident.vehicleRegistration.a ? room.incident.vehicleRegistration.a : ''}}
						</p>
					</div>
					<div class='col-12 flex align-items-center justify-content-center'>
						<p-chip>
							<p class='custom-p custom-color'>{{getEnumName(room.incident.incidentType && room.incident.incidentType ? room.incident.incidentType : '')}}</p>
						</p-chip>
					</div>
				</div>
			</td>
			<td class='col-4'>
				<div class='grid'>
					<div class='col-12 flex align-items-center justify-content-center'>
						<p class='flex justify-content-center align-items-center custom-p'>Nicht verbunden</p>
					</div>
					<div class='col-12 flex align-items-center justify-content-center'>
						<p class='custom-p'><i class='pi pi-times-circle custom-p' style='color: red'></i></p>
					</div>
				</div>
			</td>
		</tr>
	</ng-template>
</p-table>
</body>
<p class='spacing'></p>
<div class='footer flex-row'></div>
