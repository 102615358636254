import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Appointment } from '../models/appointment';

const APPOINTMENTS_URL = environment.host + 'Appointment';
const APPOINTMENTS_ALL_URL = environment.host + 'Appointment/All';

@Injectable({
	providedIn: 'root',
})
export class AppointmentService {
	constructor(private http: HttpClient) { }

	list(): Promise<any> {
		const result$ = this.http.get<any>(APPOINTMENTS_URL);
		return lastValueFrom(result$);
	}

	get(appointmentId: string): Promise<any> {
		const result$ = this.http.get<Appointment>(APPOINTMENTS_URL + '/' + appointmentId);
		return lastValueFrom(result$);
	}

	update(incident: Appointment) {
		const result$ = this.http.put<Appointment>(APPOINTMENTS_URL, incident);
		return lastValueFrom(result$);
	}

	deleteIncidentInquiry(appointmentId: string): Promise<any> {
		const result$ = this.http.delete<Appointment>(APPOINTMENTS_URL+ '/' + appointmentId);
		return lastValueFrom(result$);
	}

	listByPage(page: number, pageSize: number): Promise<any> {
		const result$ = this.http.get<Appointment>(APPOINTMENTS_ALL_URL, { params: { page, pageSize } });
		return lastValueFrom(result$);
	}
}
