import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { IncidentService } from '../../incident/incident.service';
import { Incident } from '../../models/incident';
import { User } from '../../models/user';
import { handleError } from '../../tools/error-helper';
import { FormControl, FormGroup } from '@angular/forms';
import { getUserFromToken, IncidentType, isAcConnectedToIncident } from '../../models/global.constants';
import { Registration } from '../../models/registration';
import { Driver } from '../../models/driver';
import { CurrentUser } from '../../models/currentUser';
import { AuthService } from '../../../auth/auth.service';
import { MeetingRoomService } from '../../service/meeting-room.service';
import { IncidentBinaries } from '../../models/incidentBinaries';
import { Observable, Observer } from 'rxjs';
import { RegistrationBinary } from '../../models/registrationBinary';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-incident-detail-view',
	templateUrl: './incident-detail-view.component.html',
	styleUrls: ['./incident-detail-view.component.scss'],
})
export class IncidentDetailViewComponent implements OnInit {
	// @Input() incidentIdProp: any;

	public registrationBinary: RegistrationBinary = new RegistrationBinary();
	public sortOptions: SelectItem[] = [];
	public sortOrder!: number;
	public sortField!: string;
	public images: any[] = [];
	public activeIndex: number = 0;
	public allActiveIndex: number = 0;
	public displayCustom!: boolean;
	public isOnUpload: boolean = false;
	public uploadedFiles: any[] = [];
	public deleteIncident: boolean = false;
	public incidents: Incident[] = [];
	public incidentTypes = IncidentType;
	public editIncident!: Incident;
	public currentUser: CurrentUser = getUserFromToken();
	public base64Image: any;
	public syncToAutoClaimPopup: boolean = false;
	public autoClaimLoad: boolean = false;
	public isAcConnectedToIncident: boolean = false;
	public imgIsNotLoad: boolean = false;

	public incidentDetailViewForm: FormGroup;
	public loading: boolean = false;
	public incident: Incident = new Incident();
	public incidentId: string = '';
	public binaries: IncidentBinaries[] = [];
	public binariesInclVR: any[] = [];
	public registrationBinaries: RegistrationBinary[] = [];
	public addDocumentVisible: boolean = false;

	constructor(
		public meetingRoomService: MeetingRoomService,
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private activatedRoute: ActivatedRoute,
		private incidentService: IncidentService,
		private messageService: MessageService,
		private router: Router
	) {
		this.incidentDetailViewForm = new FormGroup({
			// Amtliches Kennzeichen
			a: new FormControl({ value: '', disabled: true }),
			// Name oder Firmenname
			cDotOneDotOne: new FormControl({ value: '', disabled: true }),
			// Vorname(n)
			cDotOneDotTwo: new FormControl({ value: '', disabled: true }),
			// Anschrift
			cDotOneDotThree: new FormControl({ value: '', disabled: true }),
			// Erstzulassung
			b: new FormControl({ value: '', disabled: true }),
			// Fahrgestellnummer
			e: new FormControl({ value: '', disabled: true }),
			// HSN
			twoDotOne: new FormControl({ value: '', disabled: true }),
			// TSN
			twoDotTwo: new FormControl({ value: '', disabled: true }),
			//
			rawTextractResults: new FormControl({ value: '', disabled: true }),
			// Schadensart anzeigen
			showIncidentType: new FormControl({ value: '', disabled: true }),
			// Autoclaim
			autoclaimReferenceNo: new FormControl({ value: '', disabled: true }),
			// Unfalldatum
			autoclaimAccidentDateTime: new FormControl({ value: '', disabled: true }),
			// Unfallort
			autoclaimAccidentPlace: new FormControl({ value: '', disabled: true }),
			// Schadensart
			incidentType: new FormControl({ value: '', disabled: true }),
			// Telefon
			phoneNumber: new FormControl({ value: '', disabled: true }),
			// E-Mail
			email: new FormControl({ value: '', disabled: true }),
			// Abweichender Fahrer Vorname
			additionalDriverFirstName: new FormControl({ value: '', disabled: true }),
			// Abweichender Fahrer Nachname
			additionalDriverLastName: new FormControl({ value: '', disabled: true }),
			// Abweichender Fahrer Straße
			additionalDriverStreet: new FormControl({ value: '', disabled: true }),
			// Abweichender Fahrer Nummer
			additionalDriverNumber: new FormControl({ value: '', disabled: true }),
			// Abweichender Fahrer Postleitzahl
			additionalDriverZip: new FormControl({ value: '', disabled: true }),
			// Abweichender Fahrer Ort
			additionalDriverPlace: new FormControl({ value: '', disabled: true }),
		});
	}

	async ngOnInit(): Promise<void> {
		console.log(this.incident);

		this.sortOptions = [
			{ label: 'Neueste zuerst anzeigen', value: '!createdOn' },
			{ label: 'Älteste zuerst anzeigen', value: 'createdOn' },
		];

		// console.log('this.incidentId: parent', this.incidentId);
		// if(this.incidentIdProp) {
		// 	this.incidentId = this.incidentIdProp;
		// } else {
			this.activatedRoute.params.subscribe((params) => {
				this.incidentId = params['id'];
			});
		// }

		await this.loadIncident();
		this.isAcConnectedToIncident = isAcConnectedToIncident(this.incident);
	}

	onSortChange(event: any) {
		let value = event.value;
		if (value.indexOf('!') === 0) {
			this.sortOrder = -1;
			this.sortField = value.substring(1, value.length);
		} else {
			this.sortOrder = 1;
			this.sortField = value;
		}
	}

	userVisible(): boolean {
		if (this.currentUser.role) {
			return this.currentUser.role === 'Administrator' || this.currentUser.role === 'User';
		} else {
			return false;
		}
	}

	asmVisible(): boolean {
		if (this.currentUser.role) {
			return this.currentUser.role === 'Administrator' || this.currentUser.role === 'ASM';
		} else {
			return false;
		}
	}

	imageClick(index: number, isAll?: boolean) {
		if (isAll) {
			this.allActiveIndex = index;
		} else {
			this.activeIndex = index;
		}
		this.displayCustom = true;
	}

	showGalleria(preSignedS3Uri: string) {
		let index = this.getIndexByUriFromBinariesInclVR(preSignedS3Uri);
		this.imageClick(index, true);
	}

	getIndexByUriFromBinariesInclVR(uri: string): number {
		for (let i = 0; i < this.binariesInclVR.length; i++) {
			if (this.binariesInclVR[i].preSignedS3Uri === uri) {
				return i;
			}
		}
		return -1;
	}

	/**
	 * Load incident by id
	 */
	async loadIncident(): Promise<void> {
		try {
			this.loading = true;
			this.incident = await this.incidentService.get(this.incidentId);
			this.registrationBinary = await this.incidentService.getVehicleRegistrationBinary(this.incidentId);
			this.binaries = [...(await this.incidentService.loadBinaries(this.incidentId))];
			this.binaries = this.binaries.filter((inq) => inq.preSignedS3Uri !== null);
			this.binariesInclVR = [...this.binaries, this.registrationBinary];
		} catch (error) {
			this.messageService.add({
				severity: 'error',
				summary: 'Fehler',
				detail: 'Die Liste konnte nicht geladen werden.',
			});
			handleError(error, this.router);
		}
		this.loading = false;
	}

	async uploadHandler(event: any): Promise<void> {
		this.isOnUpload = true;
		for (let file of event.files) {
			this.uploadedFiles.push(file);
		}
		this.messageService.add({
			severity: 'success',
			summary: 'Erfolg',
			life: 20000,
			contentStyleClass: 'custom-toast-message',
			detail: 'Die Datei wurde hochgeladen.',
		});
		await this.uploadFiles();
		this.addDocumentVisible = false;
	}

	public async uploadFiles(): Promise<void> {
		try {
			this.binaries = [...this.binaries, await this.incidentService.multiUpload(this.uploadedFiles, this.incident.reports[0].incidentReportId)];
		} catch (error) {
			this.messageService.add({
				severity: 'error',
				summary: 'Fehler',
				detail: 'Die Datei konnte nicht hochgeladen werden.',
			});
			handleError(error, this.router);
		}
	}

	getEnumName(type: string) {
		if (type) {
			if (type === 'Liability') {
				return 'Haftpflicht';
			} else if (type === 'PartiallyComprehensive') {
				return 'Teilkasko';
			} else if (type === 'FullyComprehensive') {
				return 'Vollkasko';
			} else if (type === 'SelfPayer') {
				return 'Selbstzahler';
			} else {
				return 'Nicht gesetzt';
			}
		} else {
			return 'Nicht gesetzt';
		}
	}

	/**
	 *
	 * @param expert User
	 * @returns fullname
	 */
	fullName(expert: User): string {
		const firstName = expert?.firstName ? expert.firstName : 'Expert';
		const lastName = expert?.lastName ? expert.lastName : '';
		return `${firstName} ${lastName}`;
	}

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
		if (this.addDocumentVisible) {
			this.closeAddDocumentPopup();
		} else if (this.displayCustom) {
			this.displayCustom = false;
		}
	}

	openAddDocumentPopup() {
		this.isOnUpload = false;
		this.addDocumentVisible = true;
	}

	closeAddDocumentPopup() {
		this.addDocumentVisible = false;
	}

	cancelLinkAutoclaim() {
		this.syncToAutoClaimPopup = false;
		this.incidentDetailViewForm.disable();
	}

	getLabelForChip(incident: Incident): string {
		return incident.autoclaimReferenceNo ? incident.autoclaimReferenceNo : 'Verknüpfen';
	}

	getAcBinarySyncState(binary: IncidentBinaries): boolean {
		return !!binary.syncedToAutoclaimOn;
	}

	async syncToAutoclaim(incidentId: string) {
		await this.incidentService.syncBinariesToAutoclaim(incidentId);
		this.messageService.add({
			severity: 'info',
			summary: 'Information',
			contentStyleClass: 'custom-toast-message',
			detail: 'Synchronisation mit Autoclaim wurde gestartet, diese kann einige Minuten dauern.',
		});
	}

	downloadCombiPlusXML(incidentId: string) {
		this.incidentService.getCombiPlusXML(incidentId).subscribe((response: any) => {
			let blob: any = new Blob([response], { type: 'application/zip; charset=utf-8' });
			const url = window.URL.createObjectURL(blob);
			//window.open(url);
			saveAs(blob);
		}),
			(error: any) => console.log('Error downloading the file'),
			() => console.info('File downloaded successfully');
	}

	async linkAutoclaim() {
		this.autoClaimLoad = true;
		this.applyUpdatedEditingIncidentValues();
		await this.incidentService.update(this.incident);
		this.incidentDetailViewForm.disable();
		this.syncToAutoClaimPopup = false;
	}

	deleteBinaryConfirmDialog(incidentBinaries: IncidentBinaries) {
		this.confirmationService.confirm({
			header: 'Bild löschen',
			message: 'Wollen Sie dieses Bild wirklich löschen?',
			accept: () => this.deleteIncidentBinaries(incidentBinaries),
			acceptLabel: 'Ja',
			rejectLabel: 'Nein',
		});
	}

	deleteIncidentConfirmDialog(incident: Incident) {
		this.confirmationService.confirm({
			header: 'Kalkulation löschen',
			message: 'Wollen Sie diese Kalkulation wirklich löschen?',
			accept: () => this.deleteIncidentInquiry(incident),
			acceptLabel: 'Ja',
			rejectLabel: 'Nein',
		});
	}

	async deleteIncidentInquiry(incident: Incident) {
		if (incident.incidentId) {
			this.deleteIncident = await this.incidentService.deleteIncidentInquiry(incident.incidentId);
			if (this.deleteIncident) {
				this.incidents.forEach((element, index) => {
					if (element.incidentId === incident.incidentId) {
						this.incidents.splice(index, 1);
					}
				});
			}
			await this.router.navigate(['dashboard/expert-dashboard/incidents']);
		} else {
			this.messageService.add({
				severity: 'error',
				summary: 'Fehler',
				detail: 'Der Eintrag konnte nicht gelöscht werden.',
			});
		}
	}

	async deleteIncidentBinaries(incidentBinaries: IncidentBinaries) {
		await this.incidentService.deleteBinaries(incidentBinaries.s3Key);
		this.binaries = [...(await this.incidentService.loadBinaries(this.incidentId))];
		this.binaries = this.binaries.filter((inq) => inq.s3Uri !== null);
	}

	linkAutoclaimDialog(incident: Incident) {
		this.editIncident = incident;
		this.patchEditIncidentValues(incident);
		this.incidentDetailViewForm.enable();
		this.syncToAutoClaimPopup = true;
	}

	editIncidentButton(incident: Incident) {
		this.editIncident = incident;
		this.patchEditIncidentValues(incident);
		this.incidentDetailViewForm.enable();
	}

	cancelEditIncidentButton() {
		this.incidentDetailViewForm.disable();
		window.location.reload();
	}

	public async updateIncident() {
		try {
			this.applyUpdatedEditingIncidentValues();
			await this.incidentService.update(this.incident);
			this.incidentDetailViewForm.disable();
			this.messageService.add({
				severity: 'success',
				summary: 'Erfolg',
				detail: 'Die Kalkulation wurde erfolgreich aktualisiert.',
			});
		} catch (error) {
			this.messageService.add({
				severity: 'error',
				summary: 'Fehler',
				detail: 'Die Kalkulation konnte nicht aktualisiert werden. Bitte überpüfen Sie Ihre Netzwerkverbindung.',
			});
		}
	}

	public async restartCalculationDetailView() {
		this.incident.isRoomOpen = true;
		console.log(this.incident);
		await this.incidentService.update(this.incident);
		this.meetingRoomService.joinMeetingRoom(this.incident);
	}

	applyUpdatedEditingIncidentValues() {
		const incidentData = Object.assign(this.incidentDetailViewForm.value);

		// vehicleRegistration
		if (!this.incident.vehicleRegistration) this.incident.vehicleRegistration = new Registration();
		this.incident.vehicleRegistration.cDotOneDotTwo = incidentData.cDotOneDotTwo;
		this.incident.vehicleRegistration.cDotOneDotOne = incidentData.cDotOneDotOne;
		this.incident.vehicleRegistration.cDotOneDotThree = incidentData.cDotOneDotThree;
		this.incident.vehicleRegistration.a = incidentData.a;
		this.incident.vehicleRegistration.e = incidentData.e;
		this.incident.vehicleRegistration.b = incidentData.b;
		this.incident.vehicleRegistration.twoDotOne = incidentData.twoDotOne;
		this.incident.vehicleRegistration.twoDotTwo = incidentData.twoDotTwo;

		// autoclaim
		this.incident.autoclaimReferenceNo = incidentData.autoclaimReferenceNo;
		this.incident.accidentDateTime = incidentData.autoclaimAccidentDateTime;
		this.incident.accidentPlace = incidentData.autoclaimAccidentPlace;

		// incidentType
		this.incident.incidentType = incidentData.incidentType;

		// additionalInformation
		this.incident.email = incidentData.email;
		this.incident.phone = incidentData.phoneNumber;

		// driver
		if (!this.incident.driver) this.incident.driver = new Driver();
		this.incident.driver.firstName = incidentData.additionalDriverFirstName;
		this.incident.driver.lastName = incidentData.additionalDriverLastName;
		this.incident.driver.street = incidentData.additionalDriverStreet;
		this.incident.driver.houseNo = incidentData.additionalDriverNumber;
		this.incident.driver.postalCode = incidentData.additionalDriverZip;
		this.incident.driver.city = incidentData.additionalDriverPlace;
	}

	patchEditIncidentValues(incident: Incident) {
		if (this.incident) {
			this.incidentDetailViewForm.patchValue({
				cDotOneDotTwo: incident.vehicleRegistration.cDotOneDotTwo,
				cDotOneDotOne: incident.vehicleRegistration.cDotOneDotOne,
				cDotOneDotThree: incident.vehicleRegistration.cDotOneDotThree,
				a: incident.vehicleRegistration.a,
				e: incident.vehicleRegistration.e,
				b: incident.vehicleRegistration.b,
				twoDotOne: incident.vehicleRegistration.twoDotOne,
				twoDotTwo: incident.vehicleRegistration.twoDotTwo,
				autoclaimReferenceNo: incident.autoclaimReferenceNo,
				autoclaimAccidentDateTime: incident.accidentDateTime,
				autoclaimAccidentPlace: incident.accidentPlace,
				incidentType: incident.incidentType,
				emai: incident.email,
				phoneNumber: incident.phone,
				additionalDriverFirstName: incident.driver.firstName,
				additionalDriverLastName: incident.driver.lastName,
				additionalDriverStreet: incident.driver.street,
				additionalDriverNumber: incident.driver.houseNo,
				additionalDriverZip: incident.driver.postalCode,
				additionalDriverPlace: incident.driver.city,
			});
		}
	}

	public getThumbnailPath(originalPath: string): string {
		if (originalPath) {
			let length = originalPath.length;
			let indexDot = originalPath.lastIndexOf('.');
			let pathWithoutExtension = originalPath.substring(0, indexDot);
			let fileExtension = originalPath.substring(indexDot, length);
			return pathWithoutExtension + '_thumb' + fileExtension;
		}
		return '';
	}

	downloadImage(imageUrl: string, originalFilename: string) {
		this.getBase64ImageFromURL(imageUrl).subscribe((base64data: string) => {
			this.base64Image = 'data:image/jpg;base64,' + base64data;
			// save image to disk
			let link = document.createElement('a');

			document.body.appendChild(link); // for Firefox

			link.setAttribute('href', this.base64Image);
			link.setAttribute('download', originalFilename);
			link.click();
		});
	}

	public handleMissingImage(event: Event) {
		(event.target as HTMLImageElement).classList.add('rotate');
		(event.target as HTMLImageElement).classList.remove('custom-img');
		(event.target as HTMLImageElement).src = 'assets/icons/vector_72w.png';
	}

	getBase64ImageFromURL(url: string) {
		return Observable.create((observer: Observer<string>) => {
			const img: HTMLImageElement = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = url;
			if (!img.complete) {
				img.onload = () => {
					observer.next(this.getBase64Image(img));
					observer.complete();
				};
				img.onerror = (err) => {
					observer.error(err);
				};
			} else {
				observer.next(this.getBase64Image(img));
				observer.complete();
			}
		});
	}

	getBase64Image(img: HTMLImageElement) {
		const canvas: HTMLCanvasElement = document.createElement('canvas');
		canvas.width = img.width;
		canvas.height = img.height;
		// @ts-ignore
		const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0);
		const dataURL: string = canvas.toDataURL('image/png');

		return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
	}
}
