export class Driver {
	city = '';
	firstName = '';
	houseNo = '';
	lastName = '';
	postalCode = '';
	street = '';
	incidentId = '';
	vehicleDriverId = '';
}
