import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PermissionsService } from '../service/permissions.service';
import { Role } from '../models/role';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
	@Input() public menuActive: boolean | undefined;
	public expertItems: MenuItem[] = [];
	public userItems: MenuItem[] = [];
	public roles = Role;

	constructor(public permissionsService: PermissionsService) {}

	public ngOnInit(): void {
		// Start Expert Menuitems
		const expertMenuItem: MenuItem = {
			label: 'Dashboard',
			icon: 'pi pi-compass',
			routerLink: '/dashboard/expert-dashboard',
		};

		const manualIncidentMenuItem: MenuItem = {
			label: 'Manuelle Kalkulation',
			icon: 'pi pi-camera',
			routerLink: 'dashboard/expert-dashboard/manual-incident',
		};

		const incidentsMenuItem: MenuItem = {
			label: 'Schadensvorgänge',
			icon: 'pi pi-folder-open',
			routerLink: 'dashboard/expert-dashboard/incidents',
		};

		const inquiryGuidedIncidentsMenuItem: MenuItem = {
			label: 'Offene Gutachtenanfragen',
			icon: 'pi pi-inbox',
			routerLink: 'dashboard/expert-dashboard/inquiry-guided-incidents',
		};

		const appointmentOverviewMenuItem: MenuItem = {
			label: 'Terminübersicht',
			icon: 'pi pi-calendar',
			routerLink: 'dashboard/expert-dashboard/appointment-overview',
		};

		const waitingRoomMenuItem: MenuItem = {
			label: 'Digitaler Warteraum',
			icon: 'pi pi-desktop',
			routerLink: 'waiting-room',
			target: '_blank',
		};
		// End Expert Menuitems

		// Start Employee Menuitems
		const userMenuItem: MenuItem = {
			label: 'Dashboard',
			icon: 'pi pi-compass',
			routerLink: '/dashboard/employee-dashboard',
		};

		const guidedIncidentMenuItem: MenuItem = {
			label: 'Geführte Kalkulation',
			icon: 'pi pi-camera',
			routerLink: '/dashboard/employee-dashboard/guided-incident',
		};

		const personalIncidentsMenuItem: MenuItem = {
			label: 'Offene Anfragen',
			icon: 'pi pi-inbox',
			routerLink: '/dashboard/employee-dashboard/personal-incidents',
		};

		const inquiryOpenIncidentsMenuItem: MenuItem = {
			label: 'Meine Schadensvorgänge',
			icon: 'pi pi-folder-open',
			routerLink: '/dashboard/employee-dashboard/inquiry-open-incidents',
		};

		const smartVDSMobileMenuItem: MenuItem = {
			label: 'Smart-VDS Mobile',
			icon: 'pi pi-mobile',
			routerLink: '/dashboard/employee-dashboard/smart-vds-mobile',
		};
		// End Employee Menuitems

		this.expertItems = [expertMenuItem, inquiryGuidedIncidentsMenuItem, manualIncidentMenuItem, incidentsMenuItem, appointmentOverviewMenuItem, waitingRoomMenuItem];
		this.userItems = [userMenuItem, guidedIncidentMenuItem, personalIncidentsMenuItem, inquiryOpenIncidentsMenuItem, smartVDSMobileMenuItem];
	}

	public activateMenu(event: any): void {
		let node;
		if (event.target.classList.contains('p-submenu-header') == true) {
			node = 'submenu';
		} else if (event.target.tagName === 'SPAN') {
			node = event.target.parentNode.parentNode;
		} else {
			node = event.target.parentNode;
		}
		if (node != 'submenu') {
			let menuitem = document.getElementsByClassName('p-menuitem-active');
			for (let i = 0; i < menuitem.length; i++) {
				menuitem[i].classList.remove('p-menuitem-active');
			}
			node.classList.add('p-menuitem-active');
		}
	}
}
