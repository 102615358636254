import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService) {}

	public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.authService.isAuthenticated()) {
			console.error(this.authService.isAuthenticated());
			this.authService.logout();
			return false;
		}
		return true;
	}
}
