import { AbstractControl } from '@angular/forms';
import { RegistrationBinary } from './registrationBinary';

export class Registration {
	a: AbstractControl | null | undefined;
	cDotOneDotOne: AbstractControl | null | undefined;
	cDotOneDotTwo: AbstractControl | null | undefined;
	cDotOneDotThree: AbstractControl | null | undefined;
	b: AbstractControl | null | undefined;
	cDot4C!: string;
	bDate: AbstractControl | null | undefined;
	twoDotOne: AbstractControl | null | undefined;
	twoDotTwo: AbstractControl | null | undefined;
	e: AbstractControl | null | undefined;
	createdOn!: string;
	incidentId!: string;
	binary: RegistrationBinary = new RegistrationBinary();
}
