import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Role } from '../shared/models/role';
import { PermissionsService } from '../shared/service/permissions.service';
import { Router } from '@angular/router';
import { getUserFromToken, GlobalConstants } from '../shared/models/global.constants';
import { MessageService } from 'primeng/api';
import { CurrentUser } from '../shared/models/currentUser';
import { MeetingRoomService } from '../shared/service/meeting-room.service';
import { Room } from '../shared/models/room';
import { Incident } from '../shared/models/incident';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	providers: [MessageService],
})
export class DashboardComponent implements OnInit {
	public stylePeerCard = { background: '#f2f4f7', marginBottom: '2em' };
	public currentMeetingRoomSideBar: boolean = false;
	public menuActive: boolean = true;
	public items: any[] = [];
	public rooms: Room[] = [];
	public currentUser: CurrentUser = getUserFromToken();
	public expertview: boolean = this.currentUser.role === Role.Administrator;
	public role: Role = Role.None;
	public incident: Incident = new Incident();

	constructor(public meetingRoomService: MeetingRoomService, private authService: AuthService, public permissionsService: PermissionsService, public router: Router) {}

	public ngOnInit(): void {
		this.items = [
			{
				value: 'Benutzerverwaltung',
				id: 1,
			},
			{
				value: 'Logout',
				id: 2,
			},
		];
	}

	public async onCurrentMeetingRoomButtonClick(): Promise<void> {
		this.rooms = await this.meetingRoomService.getCurrentOpenMeetingRoom();
		this.currentMeetingRoomSideBar = true;
	}

	public checkPermissions() {
		return this.currentUser.role === Role.Administrator || this.currentUser.role === Role.ASM;
	}

	public onMenuButtonClick(): void {
		GlobalConstants.sidebarActive = !GlobalConstants.sidebarActive;
		this.menuActive = GlobalConstants.sidebarActive;
	}

	public onMaskClick(): void {
		GlobalConstants.sidebarActive = false;
		this.menuActive = false;
	}

	public logout(): void {
		this.authService.logout();
	}

	public openUsermanagement() {
		this.router.navigate(['dashboard/users']);
	}

	public clickAction(id: any): void {
		if (id === 1) {
			this.openUsermanagement();
		} else if (id === 2) {
			this.logout();
		}
	}
}
