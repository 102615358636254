import { environment } from '../../../environments/environment';
import { CurrentUser } from './currentUser';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Role } from './role';
import { Incident } from './incident';

export const GlobalConstants = {
	host: environment.host,
	JWT: 'PRI_JWT',
	sidebarActive: true,
};

export function api(path: string): string {
	return GlobalConstants.host + path;
}

export enum IncidentType {
	none = 'None',
	liability = 'Liability',
	partiallyComprehensive = 'PartiallyComprehensive',
	fullyComprehensive = 'FullyComprehensive',
	//selfpayer = 'Selfpayer',
}

export function getUserFromToken(): CurrentUser {
	try {
		let token = localStorage.getItem(GlobalConstants.JWT);
		if (token) {
			const decode = jwtDecode<JwtPayload>(token);
			let currentUser = new CurrentUser();
			// @ts-ignore
			let stringRole = decode.role;
			// @ts-ignore
			currentUser.userId = decode.nameid;
			// @ts-ignore
			currentUser.email = decode.email;
			currentUser.role = getRoleFromString(stringRole);
			return currentUser;
		}
	} catch (Error) {
		console.warn(Error);
	}
	return new CurrentUser();
}

export function getRoleFromString(r: string): Role {
	if (r === Role.Administrator) {
		return Role.Administrator;
	} else if (r === Role.ASM) {
		return Role.ASM;
	} else if (r === Role.User) {
		return Role.User;
	} else if (r === Role.External) {
		return Role.External;
	} else {
		return Role.None;
	}
}

export function isAcConnectedToIncident(incident: Incident): boolean {
	if (incident) {
		return !!incident.autoclaimRecordId;
	}
	return false;
}
