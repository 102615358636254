import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Message } from 'primeng/api';

@Component({
	selector: 'app-login',
	templateUrl: './activate.component.html',
	styleUrls: ['./activate.component.css'],
})
export class ActivateComponent implements OnInit {
	msgs: Message[] = [];
	// @ts-ignore
	activateForm: FormGroup;
	submitted = false;
	isloading = false;
	userActivationId = '';

	constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService, private activatedRoute: ActivatedRoute) {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.userActivationId = params['id'];
		});
	}

	ngOnInit(): void {
		this.activateForm = this.formBuilder.group({
			password: ['', Validators.required],
		});
	}

	get formControls(): any {
		return this.activateForm.controls;
	}

	async activate(): Promise<void> {
		this.submitted = true;
		this.isloading = true;
		// @ts-ignore
		let password = this.activateForm.get('password').value;

		if (this.userActivationId != '' && this.userActivationId != undefined && password) {
			let ok = await this.authService.activate(password, this.userActivationId);
			if (ok) {
				this.isloading = false;
				await this.router.navigateByUrl('/login');
			} else {
				this.isloading = false;
				this.msgs = [
					{
						severity: 'error',
						summary: 'Error',
						detail: 'Das Passwort konnte nicht gespeichert werden. Wenden Sie sich bitte an Ihren Administrator.',
					},
				];
			}
		} else {
			this.isloading = false;
			this.msgs = [
				{
					severity: 'error',
					summary: 'Error',
					detail: 'Das Passwort konnte nicht gespeichert werden. Wenden Sie sich bitte an Ihren Administrator.',
				},
			];
		}
	}
}
