export class RegistrationBinary {
	incidentId = '';
	createdOn: Date = new Date();
	fileSize = '';
	originalFilename = '';
	s3Key = '';
	s3KeyThumbnail = '';
	s3Uri = '';
	s3UriThumbnail = '';
	preSignedS3Uri = '';
	preSignedS3UriThumbnail = '';
	//syncedToAutoclaimOn: Date = new Date();
}
