import { AppointmentService } from './../../service/appointment.service';
import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MeetingRoomService } from '../../service/meeting-room.service';
import { WaitingRoomModel } from '../../models/waitingRoomModel';
import { IncidentService } from '../../incident/incident.service';
import { Appointment } from '../../models/appointment';
import { timeStamp } from 'console';

@Component({
	selector: 'app-waiting-room',
	templateUrl: './waiting-room.component.html',
	styleUrls: ['./waiting-room.component.scss'],
})
export class WaitingRoomComponent implements OnInit {
	public rooms: WaitingRoomModel[] = [];
	public appointments: Appointment[] = [];
	public now: Date = new Date();
	public isCustomerPaneVisible: boolean = false;
	public paneWidth: string = '30%';
	public loadingAppointments: boolean = false;
	public isCollapsed: boolean = false;

	constructor(private meetingRoomService: MeetingRoomService, private changeDetector: ChangeDetectorRef, private incidentService: IncidentService,
		private appointmentService: AppointmentService
	) {
		setInterval(() => {
			this.now = new Date();
		}, 1000);

		setInterval(async () => {
			this.rooms = await this.getIncompleteMeetingRoom();

			for (let i = 0; i < this.rooms.length; i++) {
				if (typeof this.rooms[i].incident === 'undefined') {
					this.rooms[i].incident = await this.incidentService.get(this.rooms[i].room_parameter);
				}
			}
		}, 10000);
	}

	getEnumName(type: string) {
		if (type) {
			if (type === 'Liability') {
				return 'Haftpflicht';
			} else if (type === 'PartiallyComprehensive') {
				return 'Teilkasko';
			} else if (type === 'FullyComprehensive') {
				return 'Vollkasko';
			} else if (type === 'SelfPayer') {
				return 'Selbstzahler';
			} else {
				return 'Nicht gesetzt';
			}
		} else {
			return 'Nicht gesetzt';
		}
	}

	async ngOnInit() {
		this.getMeetingRooms();
		this.appointments = await this.getAppointments();
	}
	
	async getMeetingRooms() {
		const rooms = await this.getIncompleteMeetingRoom();
		if (rooms) {
			this.rooms = rooms;
		}
		return;
	}

	ngAfterContentChecked(): void {
		this.changeDetector.detectChanges();
	}

	getWaitingTime(dateTime: string): string {
		if (dateTime) {
			let hours = dateTime.charAt(0);
			hours = hours + dateTime.charAt(1);

			let minutes = dateTime.charAt(3);
			minutes = minutes + dateTime.charAt(4);

			if (Number(hours) < 1) {
				if (Number(minutes) < 10) {
					return 'waiting-time-tr-yellow';
				} else {
					return 'waiting-time-tr-red';
				}
			} else {
				return 'waiting-time-tr-red';
			}
		} else {
			console.error('datetime not set');
			return '';
		}
	}

	async getIncompleteMeetingRoom(): Promise<WaitingRoomModel[]> {
		let meetingRooms = await this.meetingRoomService.getCurrentOpenMeetingRoom();
		return meetingRooms.filter((mr: { expert_name: string }) => mr.expert_name === undefined);
	}

	async getAppointments(): Promise<any> {
		const appointments = await this.appointmentService.list();
		this.appointments = appointments;
		this.loadingAppointments = false;
	}

	openMeetingLink(meetingRoomLink: string) {
		if (meetingRoomLink) {
			window.open(meetingRoomLink, '_blank');
		} else {
			console.error("Meeting room link is not provided!");
		}
	}

	toggleCollapse(): void {
		const sidebar = document.getElementById('sidebar');
		const content = document.getElementById('content');
		if (sidebar && content) {
			this.isCollapsed = !this.isCollapsed;
		}
	}
}
