<div [ngClass]="{ hideSideBar: menuActive }" class="layout-sidebar hideSideBar">
	<a [routerLink]="['/dashboard']" class="logo">
		<img alt="logo" src="assets/images/logo.png" />
	</a>
	<div class="layout-menu">
		<div class="menu-items">
			<p-panelMenu *ngIf="permissionsService.selectedOption.value === roles.ASM" class="pt-xl-3" [model]="expertItems" (click)="activateMenu($event)"></p-panelMenu>
			<p-panelMenu *ngIf="permissionsService.selectedOption.value === roles.User" class="pt-xl-3" [model]="userItems" (click)="activateMenu($event)"></p-panelMenu>
		</div>
	</div>
</div>
