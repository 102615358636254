<!-- <p class='spacing'></p> -->
<div class='layout-wrapper'>
	<div class='content' [ngClass]="isCollapsed? 'collapsed': ''" id="content">
		<!--  <a *ngIf='checkPermissions()' (click)='onCurrentMeetingRoomButtonClick()' class='layout-config-button'-->
		<!--    style='cursor: pointer'>-->
		<!--    <i class='pi pi-video'></i>-->
		<!--  </a>-->
		<div class='layout-content-wrapper'>
			<p-header>
				<div class='grid custom-header'>
					<div class='col-4 flex align-items-center justify-content-center'>
						<i style='color: #22C36D' class='pi pi-clock custom-icon'></i>
						<h1 style='color: #001E59' class='custom-value'> {{now | date: "HH:mm:ss"}} </h1>
					</div>
					<div class='col-4 flex align-items-center justify-content-center'>
						<img alt='ploechinger-logo' class='mobile-logo' id='logo-mobile' src='assets/images/logo.png' />
					</div>
					<div class='col-4 flex align-items-center justify-content-center'>
						<i style='color: #22C36D' class='pi pi-video custom-icon'></i>
						<h1 style='color: #001E59' class='custom-value'>{{rooms.length}} </h1>
						<!-- add open rooms here -->
					</div>
					<button pButton class="sidebar__collapse-button"
						icon="{{ !isCollapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-left' }}"
						(click)="toggleCollapse()"></button>
				</div>
			</p-header>

			<body>
				<p-table [value]='rooms' dataKey='id' [rows]='5' [showCurrentPageReport]='true'
					styleClass='p-datatable-gridlines' [paginator]='true'>
					<ng-template pTemplate='header'>
						<tr>
							<th class='col-4 custom-th'>Service</th>
							<th class='col-4 custom-th'></th>
							<th class='col-4 custom-th'>ASM</th>
						</tr>
					</ng-template>
					<ng-template pTemplate='body' let-room>
						<tr [ngClass]='getWaitingTime(room.employee_time)'>
							<td class='col-4'>
								<div class='grid'>
									<div class='col-12 flex align-items-center justify-content-center'>
										<p class='flex justify-content-center align-items-center custom-p'>
											{{room.employee_name}}
										</p>
									</div>
									<div class='col-12 flex align-items-center justify-content-center'>
										<p class='custom-p'>{{room.employee_time}}</p>
									</div>
								</div>
							</td>
							<td class='col-4'>
								<div *ngIf='room.incident' class='grid'>
									<div class='col-12 flex align-items-center justify-content-center'>
										<p class='flex justify-content-center align-items-center custom-p'>
											{{room.incident.vehicleRegistration ?
											room.incident.vehicleRegistration.cDotOneDotTwo +
											' ' + room.incident.vehicleRegistration.cDotOneDotOne : ''}}
										</p>
									</div>
									<div class='col-12 flex align-items-center justify-content-center'>
										<p class='custom-p'>
											{{room.incident.vehicleRegistration && room.incident.vehicleRegistration.a ?
											room.incident.vehicleRegistration.a : ''}}
										</p>
									</div>
									<div class='col-12 flex align-items-center justify-content-center'>
										<p-chip>
											<p class='custom-p custom-color'>{{getEnumName(room.incident.incidentType &&
												room.incident.incidentType ? room.incident.incidentType : '')}}</p>
										</p-chip>
									</div>
								</div>
							</td>
							<td class='col-4'>
								<div class='grid'>
									<div class='col-12 flex align-items-center justify-content-center'>
										<p class='flex justify-content-center align-items-center custom-p'>Nicht
											verbunden
										</p>
									</div>
									<div class='col-12 flex align-items-center justify-content-center'>
										<p class='custom-p'><i class='pi pi-times-circle custom-p'
												style='color: red'></i>
										</p>
									</div>
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</body>
			<p class='spacing'></p>
			<div class='footer flex-row'></div>
		</div>
	</div>
	<div class="sidebar" [ngClass]="isCollapsed? 'collapsed': ''" id="sidebar">
		<h3 class="p-4 pb-0 appointment-header">Bevorstehende Kundenanrufe</h3>
		<div class="appointments-container" *ngIf="appointments && appointments.length > 0; else noCalls">
			<div *ngFor="let call of appointments" class="appointment-card">
				<p-card class="mt-3">
					<ng-template pTemplate="header">
						<div class="flex justify-content-between align-items-center p-4 pb-0">
							<h4 class="appointment-header">{{call.company}}</h4>
							<div (click)="openMeetingLink(call.meetingRoomLink)" class="meeting-icon"
								pTooltip="Meeting starten" tooltipPosition="top">
								<i class="pi pi-external-link"></i>
							</div>
						</div>
					</ng-template>
					<p class="appointment-p">Kundenname: {{ call.firstName }} {{ call.lastName }}</p>
					<p class="appointment-p">E-Mil: {{ call.email }}</p>
					<p class="appointment-p">Datum: {{ call.date | date: 'shortDate' }}</p>
				</p-card>
			</div>
		</div>
		<ng-template #noCalls>
			<div class="col-12 text-center">
				<p>Keine bevorstehenden Anrufe.</p>
			</div>
		</ng-template>
	</div>
</div>