import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { api, GlobalConstants } from '../shared/models/global.constants';
import { lastValueFrom } from 'rxjs';
import { CurrentUser } from '../shared/models/currentUser';

const url = api('Users');
const currentUser = url + '/Current';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public cachedRequests: Array<HttpRequest<any>> = [];

	constructor(private router: Router, private jwtHelper: JwtHelperService, private http: HttpClient) {}

	public async authenticate(credentials: { email: string; password: string }): Promise<boolean> {
		try {
			const result: any = await this.http.post(`${url}/Authenticate`, credentials).toPromise();
			if (result.token) {
				this.applyToken(result.token);
				return true;
			}
			return false;
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	public async activate(password: string, userActicationId: string): Promise<boolean> {
		try {
			const httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
			};
			const body = { value: password };
			const result: any = await this.http.post(`${url}/Activate/` + userActicationId, body, httpOptions).toPromise();
			return !!result;
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	public applyToken(token: string): void {
		localStorage.setItem(GlobalConstants.JWT, token);
	}

	public async logout(): Promise<any> {
		try {
			const token = this.getToken();
			if (token) {
				const body = { value: token };
				await this.http.put(`${url}/Logout`, body).toPromise();
				localStorage.removeItem('PRI_JWT');
			}
			return await this.router.navigate(['/login']);
		} catch (e) {
			localStorage.removeItem('PRI_JWT');
			return await this.router.navigate(['/login']);
		}
	}

	public getToken(): string {
		const token = localStorage.getItem(GlobalConstants.JWT);
		if (!token) {
			return '';
		}
		return token;
	}

	public collectFailedRequest(request: HttpRequest<any>): void {
		this.cachedRequests.push(request);
	}

	public isAuthenticated(): boolean {
		try {
			const token = this.getToken();
			return !this.jwtHelper.isTokenExpired(token);
		} catch (e) {
			return false;
		}
	}

	public async getCurrentUser(): Promise<CurrentUser> {
		const result$ = this.http.get<CurrentUser>(currentUser);
		return lastValueFrom(result$);
	}

	public getCurrentUserEntry() {
		return this.http.get(currentUser);
	}
}
