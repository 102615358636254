import { Driver } from './driver';
import { Report } from './report';
import { User } from './user';
import { Registration } from './registration';
import { IncidentType } from './global.constants';

export class Incident {
	combiPlusId = null;
	autoclaimRecordId = '';
	autoclaimReferenceNo = '';
	accidentDateTime = '';
	accidentPlace = '';
	createdOn: Date = new Date();
	// @ts-ignore
	driver: Driver;
	incidentId = '';
	incidentType: IncidentType = IncidentType.none;
	isInitialOverRi?: boolean;
	isRoomOpen: boolean = true;
	// @ts-ignore
	reports: Report[];
	// @ts-ignore
	user: User;
	userId = '';
	vehicleRegistration: Registration = new Registration();
	archived: boolean = false;
	status = ''; // TODO: Status enum
	modified: Date = new Date();
	email = '';
	phone = '';
	rawTextractResults: string[] = [];
	isMobile: boolean = false;
}
