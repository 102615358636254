import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Incident } from '../models/incident';
import { IncidentBinaries } from '../models/incidentBinaries';
import { RegistrationBinary } from '../models/registrationBinary';
import { error } from 'protractor';
import { AutoclaimFiles } from '../models/autoclaimFiles';

const INCIDENTSURL = environment.host + 'Incidents';
const INCIDENTSALLURL = environment.host + 'Incidents/All';
const INCIDENTSCOUNTURL = environment.host + 'Incidents/Count';
//const INCIDENTSBINARIESURL = environment.host + 'Incidents/Binaries/';
const INCIDENTSBINARIESREPORTURL = environment.host + 'IncidentReports/';
const INCIDENTREPORTBINARIES = environment.host + 'IncidentReports/Binaries';
const REGISTRATIONURL = environment.host + 'VehicleRegistrations/Binaries/';
const AUTOCLAIMURL = environment.host + 'Autoclaim';

@Injectable({
	providedIn: 'root',
})
export class IncidentService {
	public errorMessage = '';
	public res: any;

	constructor(private http: HttpClient) {}

	async create(incident: Incident): Promise<any> {
		try {
			const result$ = this.http.post(INCIDENTSURL, incident);
			return await lastValueFrom(result$);
		} catch (e) {
			console.error(e);
		}
	}

	// async syncBinariesToAutoclaim(incidentId: string): Promise<any> {
	// 	this.errorMessage = '';
	// 	return this.http.post(INCIDENTSURL + '/' + incidentId + '/Binaries/SyncToAutoclaim', incidentId).subscribe(
	// 		(response) => {
	// 			//Next callback
	// 			console.log('response received: ' + response);
	// 		},
	// 		(error) => {
	// 			//Error callback
	// 			console.error('error caught in component');
	// 			this.errorMessage = error;
	// 			throw error;
	// 		}
	// 	);
	// }

	async syncBinariesToAutoclaim(incidentId: string): Promise<any> {
		try {
			const result$ = this.http.post(INCIDENTSURL + '/' + incidentId + '/Binaries/SyncToAutoclaim', incidentId);
			return await lastValueFrom(result$);
		} catch (error) {}
	}

	// get the total number of incidents
	count(): Promise<number> {
		const result$ = this.http.get<number>(INCIDENTSCOUNTURL);
		return lastValueFrom(result$);
	}

	// load the incidents by page
	listByPage(page: number, pageSize: number): Promise<any> {
		const result$ = this.http.get<Incident>(INCIDENTSALLURL, { params: { page, pageSize } });
		return lastValueFrom(result$);
	}

	list(): Promise<any> {
		const result$ = this.http.get<any>(INCIDENTSALLURL);
		return lastValueFrom(result$);
	}

	/**
	 * @desc return incident by id
	 * @param incidentId string
	 * @returns incident
	 */
	get(incidentId: string): Promise<any> {
		const result$ = this.http.get<Incident>(INCIDENTSURL, { params: { incidentId } });
		return lastValueFrom(result$);
	}

	//checks if an Autoclaim file with current VIN is almost existing
	getExistingAutoclaimFile(vin: any): Promise<any> {
		const result$ = this.http.get<AutoclaimFiles>(AUTOCLAIMURL, { params: { vin } });
		return lastValueFrom(result$);
	}

	getCombiPlusXML(incidentId: string): any {
		return this.http.get(environment.host + 'Incidents/' + incidentId + '/CombiPlus/ZIP', { responseType: 'blob' });
	}

	getVehicleRegistrationBinary(incidentId: string): Promise<any> {
		const result$ = this.http.get<RegistrationBinary>(REGISTRATIONURL + incidentId);
		return lastValueFrom(result$);
	}

	loadBinaries(incidentId: string): Promise<any> {
		const result$ = this.http.get<IncidentBinaries>(INCIDENTSURL + '/' + incidentId + '/Binaries');
		return lastValueFrom(result$);
	}

	deleteBinaries(key: string): Promise<any> {
		const result$ = this.http.delete<IncidentBinaries>(INCIDENTREPORTBINARIES, { params: { key } });
		return lastValueFrom(result$);
	}

	/**
	 * @desc update the given incident
	 * @param incident Incident
	 * @returns result
	 */
	update(incident: Incident) {
		const result$ = this.http.put<Incident>(INCIDENTSURL, incident);
		return lastValueFrom(result$);
	}

	listInquiries(): Promise<Incident[]> {
		const result$ = this.http.get<Incident[]>(INCIDENTSALLURL);
		return lastValueFrom(result$);
	}

	deleteIncidentInquiry(incidentId: string): Promise<any> {
		const result$ = this.http.delete<Incident>(INCIDENTSURL, { params: { incidentId } });
		return lastValueFrom(result$);
	}

	async uploadCombiPlusXML(files: File[]): Promise<any> {
		const formData = new FormData();
		files.forEach((file) => formData.append(file.name, file));
		try {
			const uploadUrl = environment.host + 'Incidents/CombiPlus/XML';
			const result$ = this.http.post(uploadUrl, formData);
			console.log('blubiblub');
			console.log(formData);
			return await lastValueFrom(result$);
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	async multiUpload(files: File[], irid: string): Promise<any> {
		const formData = new FormData();
		files.forEach((file) => formData.append(file.name, file));
		try {
			const uploadUrl = INCIDENTSBINARIESREPORTURL + irid + '/Binaries/Multi';
			const result$ = this.http.post(uploadUrl, formData);
			return await lastValueFrom(result$);
		} catch (e) {
			console.error(e);
			return false;
		}
	}
}
