<div class='login_cont'>
	<div class='form_cont'>
		<p-messages [value]='msgs'></p-messages>
		<div [hidden]='!isloading' class='progress-spinner'>
			<p-progressSpinner></p-progressSpinner>
		</div>
		<div class='form'>
			<form [formGroup]='activateForm' (ngSubmit)='activate()'>
				<img src='./assets/images/logo.png' alt='logo' />
				<p class='placeholder-10'></p>
				<div>
					<b>Bitte vergeben Sie Ihr neues Passwort.</b>
				</div>
				<p class='placeholder-10'></p>
				<p-password placeholder='Passwort' class='password' [toggleMask]='true' formControlName='password'></p-password>
				<div *ngIf='formControls.password.errors && submitted'>
					<small class='p-error' *ngIf='formControls.password.errors.required'>Bitte das Passwort eingeben.</small>
				</div>
				<p class='placeholder-10'></p>
				<button type='submit' class='login-button action-btn'>Passwort speichern</button>
			</form>
			<p class='placeholder-10'></p>
			<a href='https://www.ploechinger.de/impressum/' target='_blank'>Impressum</a>
			|
			<a href='https://www.ploechinger.de/datenschutz/' target='_blank'>Datenschutz</a>
		</div>
	</div>
</div>
