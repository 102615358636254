import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '../models/role';
import { PermissionsService } from '../service/permissions.service';
import { AuthService } from '../../auth/auth.service';
import { CurrentUser } from '../models/currentUser';

@Directive({
	selector: '[appGranted]',
})
export class GrantedDirective implements OnInit {
	public currentUser: CurrentUser = new CurrentUser();

	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private permissionsService: PermissionsService, private authService: AuthService) {}

	ngOnInit() {}

	@Input() set appGranted(role: Role | Role[]) {
		if (this.permissionsService.isGranted(role)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
