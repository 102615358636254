import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Message } from 'primeng/api';
import { getUserFromToken } from '../../shared/models/global.constants';
import { Role } from '../../shared/models/role';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	msgs: Message[] = [];
	// @ts-ignore
	loginForm: FormGroup;
	submitted = false;
	isloading = false;
	termsDialogVisible: boolean = false;
	privacyDialogVisible: boolean = false;

	constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) {}

	ngOnInit(): void {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});
	}

	get formControls(): any {
		return this.loginForm.controls;
	}

	async login(): Promise<void> {
		this.submitted = true;
		this.isloading = true;
		const credentials: any = Object.assign(this.loginForm.value);
		const ok = await this.authService.authenticate(credentials);
		if (ok) {
			this.isloading = false;
			//let role = getCurrentUser().role;
			let currentUser = getUserFromToken();
			if (currentUser.role === Role.ASM || currentUser.role === Role.Administrator) {
				await this.router.navigateByUrl('/dashboard/expert-dashboard');
			} else if (currentUser.role === Role.External) {
				await this.authService.logout();
			} else {
				await this.router.navigateByUrl('/dashboard/employee-dashboard');
			}
		} else {
			this.isloading = false;
			this.msgs = [{ severity: 'error', summary: 'Error', detail: 'ungültiger Benutzername oder Passwort' }];
		}
	}

	termsPopup() {
		this.termsDialogVisible = true;
	}

	privacyPopup() {
		this.privacyDialogVisible = true;
	}
}
